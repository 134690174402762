export enum FilterMatcher {
    AND = 'and',
    OR = 'or',
}

export enum FilterOperator {
    ANY_OF = 'any_of',
    ALL_OF = 'all_of',
    NONE_OF = 'none_of',
    LESS_THAN = 'less_than',
    LESS_OR_EQUAL = 'less_or_equal',
    GREATER_THAN = 'greater_than',
    GREATER_OR_EQUAL = 'greater_or_equal',
}

export enum TransactionExtendedType {
    PURCHASE = 'PURCHASE',
    CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
    REFUND = 'REFUND',
    BALANCE_INQUIRY = 'BALANCE_INQUIRY',
    ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
    WALLET_DEBIT = 'WALLET_DEBIT',
    WALLET_CREDIT = 'WALLET_CREDIT',
    WALLET_TRANSFER = 'WALLET_TRANSFER',
    WALLET_DEBIT_REVERSAL= 'WALLET_DEBIT_REVERSAL',
    WALLET_CREDIT_REVERSAL= 'WALLET_CREDIT_REVERSAL',
    INITIAL_LOAD= 'INITIAL_LOAD',
    OCT = 'OCT',
    AFT = 'AFT',
}

export enum MessageType {
    AUTHORIZATION = 'AUTHORIZATION',
    AUTHORIZATION_ADVICE = 'AUTHORIZATION_ADVICE',
    REVERSAL = 'REVERSAL',
    FINANCIAL = 'FINANCIAL',
    FINANCIAL_ADVICE = 'FINANCIAL_ADVICE',
    REVERSAL_ADVICE = 'REVERSAL_ADVICE',
    CLEARING = 'CLEARING',
    CLEARING_REVERSAL = 'CLEARING_REVERSAL',
    API = 'API',
}

export enum AuthExpiredByType {
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
}

export enum ConfirmationType {
    YES = 'YES',
    NO = 'NO',
}

export enum TransferSourceChannel {
    API = 'API',
    DAPI = 'DAPI',
    MPGS = 'MPGS',
}

export enum LoadTransactionType {
    HOLD = 'HOLD',
    POST = 'POST',
    RELEASE = 'RELEASE',
}

export enum EcommerceSecurityLevel {
    THREE_DS_ONE = '3DS1',
    THREE_DS_TWO = '3DS2',
    THREE_DS_TWO_POINT_TWO = '3DS2.2',
}

export enum CardEntry {
    UNKNOWN = 'UNKNOWN',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
    MAGNETIC_STRIPE = 'MAGNETIC_STRIPE',
    ICC = 'ICC',
    CARD_ON_FILE = 'CARD_ON_FILE',
}

export enum PosEnvironment {
    UNKNOWN = 'UNKNOWN',
    ATTENDED_POS = 'ATTENDED_POS',
    ECOMMERCE = 'ECOMMERCE',
}
export enum WalletType {
    FUNDS_TRANSFER = 'FUNDS_TRANSFER',
    BILL_PAYMENT = 'BILL_PAYMENT',
    CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
    LOAD_CHECKOUT = 'LOAD_CHECKOUT',
    LOAD_LEAN = 'LOAD_LEAN',
    REMITTANCES = 'REMITTANCES', 
}
