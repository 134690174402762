import { customerTenant } from '@env/environment';

type TenantKeys = (typeof customerTenant)[keyof typeof customerTenant] | 'DEFAULT';

export const tenantImagesMap: Record<TenantKeys, Record<string, string>> = {
    [customerTenant.FIB]: {},
    [customerTenant.INC]: {
        logo: '/assets/inc-neo/logo.svg',
        welcome_logo: '/assets/inc-neo/logo.svg',
        sidenav_logo: '/assets/inc-neo/sidenav-logo.svg',
        welcome_screen_bg: '/assets/inc-neo/welcome-screen-bg.svg',
        login_bg: '',
        favicon: '/assets/inc-neo/favicon.ico',
    },
    [customerTenant.NASS]: {
        logo: '/assets/tenant37/logo.png',
        welcome_logo: '/assets/tenant37/welcome-screen-logo.png',
        sidenav_logo: '/assets/tenant37/logo.png',
        welcome_screen_bg: '/assets/tenant37/welcome-screen-bg.png',
        login_bg: '/assets/tenant37/login-right-background-cover.mp4',
        favicon: '/assets/tenant37/favicon.ico',
    },
    DEFAULT: {
        logo: '/assets/nymcard-logo.svg',
        welcome_logo: '/assets/welcome-screen/welcome-screen-nym-logo.svg',
        sidenav_logo: '/assets/logo-white.png',
        welcome_screen_bg: '/assets/welcome-screen/welcome-screen-bg.svg',
        login_bg: '/assets/welcome-screen/login-signup-logo.png',
        favicon: '/favicon.ico',
    },
};
