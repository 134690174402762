export enum FxRateTransactionType {
    TRANSFERS = 'TRANSFERS',
    SWEEP = 'SWEEP',
}

export enum CardProductType {
    CREDIT = 'CREDIT',
    PREPAID = 'PREPAID',
}

export enum CardProductSubType {
    SECURE = 'SECURE',
    REVOLVING = 'REVOLVING',
}

export enum PinType {
    ONLINE_PIN_ONLY = 'ONLINE_PIN_ONLY',
    BOTH_ONLINE_OFFLINE = 'BOTH_ONLINE_OFFLINE',
}

export enum CardProductStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED',
}

export enum VoucherStatus {
  CANCELED = 'CANCELED',
  REDEEMED = 'REDEEMED',
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
}

export enum OperationType {
  CL_TOPUP = 'CL_TOPUP',
  CL_WITHDRAWAL = 'CL_WITHDRAWAL',
}

export enum StatementCycle {
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
}

export enum CashAdvanceLimitType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED',
}

export enum PaymentTransactionType {
    OCT = 'OCT',
    WALLET_CREDIT = 'WALLET_CREDIT',
    CARD_TOPUP = 'CARD_TOPUP',
}

export enum PaymentDistributionOrder {
    TRANSACTION_TYPE = 'TRANSACTION_TYPE',
}

export enum TransactionTypePriority {
    PURCHASE = 'PURCHASE',
    CASH_ADVANCES = 'CASH_ADVANCES',
    FEES_AND_CHARGES = 'FEES_AND_CHARGES',
    FINANCIAL_CHARGES = 'FINANCIAL_CHARGES',
}

export enum MinimumPaymentCalculationMethod {
    PERCENTAGE_OF_STATEMENT_BALANCE = 'PERCENTAGE_OF_STATEMENT_BALANCE',
}

export enum InterestTriggerDate {
    DUE_DATE = 'DUE_DATE',
    TRANSACTION_DATE = 'TRANSACTION_DATE',
}

export enum ApplicableOn {
    OVERALL_BALANCE = 'OVERALL_BALANCE',
}

export enum EffectiveFrom {
    SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum APRType {
    FIXED = 'FIXED',
}

export enum FCMethodName {
    AVERAGE_DAILY_BALANCE = 'AVERAGE_DAILY_BALANCE',
}

export enum APRName {
    PURCHASE_APR = 'PURCHASE_APR',
    CASHADVANCE_APR = 'CASHADVANCE_APR',
}
