<app-table
    (dataLoadMore)="searchWallets()"
    (dataSort)="applySort($event)"
    (dataRowAction)="goToTransactionDetail($event)"
    [dataColumns]="columns"
    [dataDefaultColumns]="defaultColumns"
    [dataHasMoreRecords]="hasMoreRecords"
    [dataLoading]="loading"
    [dataSortableColumns]="sortableColumns"
    [dataSource]="wallets"
    class="transaction-table"
    [dataAbsenceText]="noDataMessage"
    dataName="{{ walletHeading }}"
>
    <app-table-button
        (tableButtonClick)="exportExcel()"
        tableButtonFontIcon="download"
        tableButtonText="Download .xlsx"
    />

    <app-table-filter
        (checkboxChange)="applyProductTypeFilter($event)"
        filterFontIcon="credit_card"
        [filterName]="filterName"
        filterType="checkbox"
    >
        <ng-template *ngFor="let transactionWalletType of transactionWalletTypes" [filterValue]="transactionWalletType" appTableFilter>
            <span>{{ transactionWalletType | walletType }}</span>
        </ng-template>
    </app-table-filter>

    <ng-template [appTableColumnDataSource]="wallets" appTableColumn="transaction_timestamp" let-transaction>
        <p>{{ transaction.transaction_timestamp | date : 'MMM dd, yyyy hh:mm aaa' | optionalPlaceholder }}</p>
    </ng-template>

    <ng-template [appTableColumnDataSource]="wallets" appTableColumn="to_amount" let-transaction>
        <p>{{ transaction.to_amount?.toFixed(2) | currency : transaction.to_currency + ' ' | optionalPlaceholder }}</p>
    </ng-template>
</app-table>
