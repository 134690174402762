<div class="value-field-range">
    <mat-slider [disabled]="disabled" [max]="maxValue" [min]="minValue" discrete>
        <input (dragEnd)="setDragEndValue(minControl, $event.value)" [value]="minControl.value ?? 0" matSliderStartThumb />
        <input (dragEnd)="setDragEndValue(maxControl, $event.value)" [value]="maxControl.value ?? 0" matSliderEndThumb />
    </mat-slider>

    <div class="d-flex justify-content-between">
        <div class="d-flex flex-column row-gap-2">
            <input
                (input)="setInputValue(minControl, $event)"
                [class.control-input-invalid]="isInvalid(minControl)"
                [disabled]="minControl.disabled"
                [name]="createMinifiedName(minControlDisplayName)"
                [placeholder]="minControlPlaceholder"
                [value]="minControl.value"
                class="control-input"
                type="number"
            />
            <p class="fw-semibold control-input-label">{{ minControlDisplayName }}</p>
            <ul *ngIf="isInvalid(minControl)" class="d-flex flex-column m-0 p-0 validation-error">
                <li *ngFor="let error of validationErrors(minControlDisplayName, minControl.errors)">
                    <small class="text-danger w-100">{{ error }}</small>
                </li>
            </ul>
        </div>
        <div class="d-flex flex-column row-gap-2">
            <input
                (input)="setInputValue(maxControl, $event)"
                [class.control-input-invalid]="isInvalid(maxControl)"
                [disabled]="maxControl.disabled"
                [name]="createMinifiedName(maxControlDisplayName)"
                [placeholder]="maxControlPlaceholder"
                [value]="maxControl.value"
                class="control-input"
                type="number"
            />
            <p class="fw-semibold control-input-label">{{ maxControlDisplayName }}</p>
            <ul *ngIf="isInvalid(maxControl)" class="d-flex flex-column m-0 p-0 validation-error">
                <li *ngFor="let error of validationErrors(maxControlDisplayName, maxControl.errors)">
                    <small class="text-danger w-100">{{ error }}</small>
                </li>
            </ul>
        </div>
    </div>
</div>
