import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
    providedIn: 'root',
})
export class AppAssets {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/logo.svg'));
        this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/home.svg'));
        this.matIconRegistry.addSvgIcon('report', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/report.svg'));
        this.matIconRegistry.addSvgIcon('card-management', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-management.svg'));
        this.matIconRegistry.addSvgIcon('customer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/customer.svg'));
        this.matIconRegistry.addSvgIcon('transaction', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/transaction.svg'));
        this.matIconRegistry.addSvgIcon('card', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/card.svg'));
        this.matIconRegistry.addSvgIcon('card-icon-sidemenu', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-icon-sidemenu.svg'));
        this.matIconRegistry.addSvgIcon('product', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/product-management.svg'));
        this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/settings.svg'));
        this.matIconRegistry.addSvgIcon('feedback', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/feedback.svg'));
        this.matIconRegistry.addSvgIcon('api-catalog', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/api-catalog.svg'));
        this.matIconRegistry.addSvgIcon('guide', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/guide.svg'));
        this.matIconRegistry.addSvgIcon('ring', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ring.svg'));
        this.matIconRegistry.addSvgIcon('search', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/search.svg'));
        this.matIconRegistry.addSvgIcon('back', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/back.svg'));
        this.matIconRegistry.addSvgIcon('nymcard_logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/nymcard-logo.svg'));
        this.matIconRegistry.addSvgIcon('star', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/star.svg'));
        this.matIconRegistry.addSvgIcon('treeDot', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/treeDot.svg'));
        this.matIconRegistry.addSvgIcon('arrows', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrows.svg'));
        this.matIconRegistry.addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/close.svg'));
        this.matIconRegistry.addSvgIcon('arrow-up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-up.svg'));
        this.matIconRegistry.addSvgIcon('arrow-right', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-right.svg'));
        this.matIconRegistry.addSvgIcon('arrow-right-long', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-right-long.svg'));
        this.matIconRegistry.addSvgIcon('arrow-left', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-left.svg'));
        this.matIconRegistry.addSvgIcon('arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-down.svg'));
        this.matIconRegistry.addSvgIcon('checkbox', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/checkbox.svg'));
        this.matIconRegistry.addSvgIcon('double-arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/double-arrow-down.svg'));
        this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/filter.svg'));
        this.matIconRegistry.addSvgIcon('remove-filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/remove-filter.svg'));
        this.matIconRegistry.addSvgIcon('link-card-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/link-card.svg'));
        this.matIconRegistry.addSvgIcon('product', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/product.svg'));
        this.matIconRegistry.addSvgIcon('change-card-status-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/change-card-status-icon.svg'));
        this.matIconRegistry.addSvgIcon('mastercard', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mastercard.svg'));
        this.matIconRegistry.addSvgIcon('edit-logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/edit-logo.svg'));
        this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/plus.svg'));
        this.matIconRegistry.addSvgIcon('inactive-status', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/role-inactive-status.svg'));
        this.matIconRegistry.addSvgIcon('delete-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/delete-icon.svg'));
        this.matIconRegistry.addSvgIcon('card-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-icon.svg'));
        this.matIconRegistry.addSvgIcon('search-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/search-icon.svg'));
        this.matIconRegistry.addSvgIcon('user-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/user-icon.svg'));
        this.matIconRegistry.addSvgIcon('edit-pen', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/edit-pen.svg'));
        this.matIconRegistry.addSvgIcon('app-table-filter-status', domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-table-filter-status.svg'));
        this.matIconRegistry.addSvgIcon('app-user-icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-user-icon.svg'));
        this.matIconRegistry.addSvgIcon('app-sort-icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-sort-icon.svg'));
        this.matIconRegistry.addSvgIcon('card-table', domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-table.svg'));
        this.matIconRegistry.addSvgIcon('card-type', domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-type.svg'));
        this.matIconRegistry.addSvgIcon('app-linked-card', domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-linked-card.svg'));
        this.matIconRegistry.addSvgIcon('arrow-down-secondary', domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-down-secondary.svg'));
        this.matIconRegistry.addSvgIcon('arrow-down-chevron', domSanitizer.bypassSecurityTrustResourceUrl('../assets/chevron_down.svg'));
        this.matIconRegistry.addSvgIcon('arrow-up-green', domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-up-green.svg'));
        this.matIconRegistry.addSvgIcon('arrow-down-red', domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-down-red.svg'));
        this.matIconRegistry.addSvgIcon('verified', domSanitizer.bypassSecurityTrustResourceUrl('../assets/verified.svg'));
        this.matIconRegistry.addSvgIcon('add', domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon-add.svg'));
        this.matIconRegistry.addSvgIcon('remove', domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon-remove.svg'));
        this.matIconRegistry.addSvgIcon('link', domSanitizer.bypassSecurityTrustResourceUrl('../assets/link.svg'));
        this.matIconRegistry.addSvgIcon('link-blue', domSanitizer.bypassSecurityTrustResourceUrl('../assets/link-blue.svg'));
        this.matIconRegistry.addSvgIcon('unlink', domSanitizer.bypassSecurityTrustResourceUrl('../assets/unlink.svg'));
        this.matIconRegistry.addSvgIcon('status-active', domSanitizer.bypassSecurityTrustResourceUrl('../assets/status-active.svg'));
        this.matIconRegistry.addSvgIcon('status-inactive', domSanitizer.bypassSecurityTrustResourceUrl('../assets/status-inactive.svg'));
        this.matIconRegistry.addSvgIcon('access-management', domSanitizer.bypassSecurityTrustResourceUrl('../assets/access-management/access-management.svg'));
        this.matIconRegistry.addSvgIcon('access-management-header-icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/access-management/access-management-header-icon.svg'));
        this.matIconRegistry.addSvgIcon('add-button', domSanitizer.bypassSecurityTrustResourceUrl('../assets/access-management/add-button.svg'));
        this.matIconRegistry.addSvgIcon('info-more', domSanitizer.bypassSecurityTrustResourceUrl('../assets/info-more.svg'));
        this.matIconRegistry.addSvgIcon('trash', domSanitizer.bypassSecurityTrustResourceUrl('../assets/trash.svg'));
        this.matIconRegistry.addSvgIcon('create-customer-success', domSanitizer.bypassSecurityTrustResourceUrl('../assets/create-customer-success.svg'));
        this.matIconRegistry.addSvgIcon('upload', domSanitizer.bypassSecurityTrustResourceUrl('../assets/upload.svg'));
        this.matIconRegistry.addSvgIcon('snow', domSanitizer.bypassSecurityTrustResourceUrl('../assets/snow.svg'));
        this.matIconRegistry.addSvgIcon('disturb', domSanitizer.bypassSecurityTrustResourceUrl('../assets/disturb.svg'));
        this.matIconRegistry.addSvgIcon('link-light', domSanitizer.bypassSecurityTrustResourceUrl('../assets/link-light.svg'));
        this.matIconRegistry.addSvgIcon('customer-link', domSanitizer.bypassSecurityTrustResourceUrl('../assets/customer-link.svg'));
        this.matIconRegistry.addSvgIcon('customers-new', domSanitizer.bypassSecurityTrustResourceUrl('../assets/customers-new.svg'));
        this.matIconRegistry.addSvgIcon('copy-text', domSanitizer.bypassSecurityTrustResourceUrl('../assets/copy-text.svg'));
        this.matIconRegistry.addSvgIcon('local-atm', domSanitizer.bypassSecurityTrustResourceUrl('../assets/local-atm.svg'));
        this.matIconRegistry.addSvgIcon('cancel', domSanitizer.bypassSecurityTrustResourceUrl('../assets/cancel.svg'));
        this.matIconRegistry.addSvgIcon('minus', domSanitizer.bypassSecurityTrustResourceUrl('../assets/minus.svg'));
        this.matIconRegistry.addSvgIcon('checked', domSanitizer.bypassSecurityTrustResourceUrl('../assets/checked.svg'));
        this.matIconRegistry.addSvgIcon('atm-icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/atm-icon.svg'));
        this.matIconRegistry.addSvgIcon('discard', domSanitizer.bypassSecurityTrustResourceUrl('../assets/discard.svg'));
        this.matIconRegistry.addSvgIcon('save', domSanitizer.bypassSecurityTrustResourceUrl('../assets/save.svg'));
        this.matIconRegistry.addSvgIcon('customer-operations', domSanitizer.bypassSecurityTrustResourceUrl('../assets/customer-operations.svg'));
        this.matIconRegistry.addSvgIcon('cash-in-request', domSanitizer.bypassSecurityTrustResourceUrl('../assets/cash-in-request.svg'));
        this.matIconRegistry.addSvgIcon('voucher-error', domSanitizer.bypassSecurityTrustResourceUrl('../assets/voucher-error.svg'));
        this.matIconRegistry.addSvgIcon('fund-transfer', domSanitizer.bypassSecurityTrustResourceUrl('../assets/fund-transfer.svg'));
        this.matIconRegistry.addSvgIcon('faq-icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/faq-icon.svg'));
        this.matIconRegistry.addSvgIcon('download', domSanitizer.bypassSecurityTrustResourceUrl('../assets/download.svg'));
        this.matIconRegistry.addSvgIcon('success-fund-transfer', domSanitizer.bypassSecurityTrustResourceUrl('../assets/success-fund-transfer.svg'));
    }
}
