import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { LoadTransactionType, TransactionExtendedType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'transactionType',
})
export class TransactionTypePipe implements PipeTransform {
    public transform(value: Optional<TransactionExtendedType | LoadTransactionType>): string {
        switch (value) {
            case TransactionExtendedType.PURCHASE:
                return 'Purchase';

            case TransactionExtendedType.CASH_WITHDRAWAL:
                return 'Cash withdrawal';

            case TransactionExtendedType.REFUND:
                return 'Refund';

            case TransactionExtendedType.BALANCE_INQUIRY:
                return 'Balance inquiry';

            case TransactionExtendedType.ACCOUNT_VERIFICATION:
                return 'Account verification';

            case TransactionExtendedType.WALLET_DEBIT:
                return 'Wallet debit';

            case TransactionExtendedType.WALLET_CREDIT:
                return 'Wallet credit';

            case TransactionExtendedType.INITIAL_LOAD:
                return 'Wallet Initial Credit';

            case TransactionExtendedType.WALLET_DEBIT_REVERSAL:
                return 'Wallet Debit Reversal'

            case TransactionExtendedType.WALLET_CREDIT_REVERSAL:
                return 'Wallet Credit Reversal'
        
            case TransactionExtendedType.WALLET_TRANSFER:
                return 'Wallet transfer';

            case TransactionExtendedType.OCT:
                return 'Oct';

            case TransactionExtendedType.AFT:
                return 'Aft';

            case LoadTransactionType.HOLD:
                return 'Hold';

            case LoadTransactionType.POST:
                return 'Post';

            case LoadTransactionType.RELEASE:
                return 'Release';

            default:
                return '';
        }
    }
}
