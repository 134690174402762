import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-card-template',
    standalone: true,
    templateUrl: './card-template.component.html',
    styleUrls: ['./card-template.component.scss'],
    imports: [CommonModule, SpinnerComponent],
})
export class CardTemplateComponent {
    @Input() card: any | undefined;
    // @Input() isLoading:any;
    @Input() size = 'LG';
    @Input() isCardProduct = false;
    @Input() skinImg = '../../../../assets/visa-debit.svg';
    freezeImg = '../../../../assets/freeze.svg';
    tenantKey = localStorage.getItem('tenantKey')
    isNassPay:boolean=false;
    
    constructor(){
        const tenantKey = localStorage.getItem('tenantKey') || ''
        if(tenantKey.startsWith('tenant37') || tenantKey.startsWith('tenant01')) {
            this.isNassPay = true;
          }
    }

    getCardNumber(bin: string, last4: string): string {
        if (bin.length < 4 || last4.length !== 4) {
            return 'Invalid input';
        }

        const formattedCardNumber = bin.substring(0, bin.length - 2) + ' **** **** ' + last4;

        return formattedCardNumber;
    }

    getCardBin(bin: string): string {
        //adds a space after four digits
        const formattedCardNumber = bin?.replace(/(.{4})/, '$1 ') + '** **** ****';
        return formattedCardNumber;
    }
}
