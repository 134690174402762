import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    DeepPartial,
    IAccount,
    ICard,
    ICardStatusResponse,
    ICreateAccount,
    ICreateCustomer,
    ICustomer,
    ICustomerAnalyticsApiResponse,
    ICustomerCountApiResponse,
    ICustomerIdentification,
    ICustomerIdentificationFile,
    ICustomerIdentificationFlattenedFile,
    ICustomerSearchRequest,
    IIdentificationDocument,
    IListResponse,
    sdkToken,
    UserType,
} from '@lib/interfaces';
import { map, Observable, switchMap } from 'rxjs';
import { Utils } from '@lib/utils';
import { IdvReports } from '@lib/interfaces/user-idv-report.interface';

@Injectable({
    providedIn: 'root',
})
export class CustomersService {
    public environment_url: string;

    headers = new HttpHeaders({
        'x-nymos-idempotency-key': 'c9e96ea5-96ad-447c-984d-671d2f2ba54a',
        'x-no-authorization': 'true',
    });
    public constructor(private http: HttpClient, private utils: Utils) {
        this.environment_url = environment.api_url;
    }

    public createCustomer(payload: DeepPartial<ICreateCustomer>): Observable<ICustomer> {
        return this.http.post<ICustomer>(
            `${this.environment_url}nfront/v1/proxy/users`,
            {
                user_type: 'INDIVIDUAL',
                company_name: 'Example',
                ...payload,
            },
            {
                headers: new HttpHeaders({
                    Apikey: localStorage.getItem('tenantKey') || '',
                }),
            },
        );
    }

    public createAgent(payload: DeepPartial<ICreateCustomer>, agentId: string, currencies = ['USD', 'SDG', 'BHD', 'QAR', 'GBP', 'EUR', 'KWD', 'OMR', 'SAR', 'AED', 'EGP', 'CAD', 'TRY']): Observable<ICustomer> {
        if (payload.address?.postal_code) {
            payload.address.postal_code = String(payload.address?.postal_code);
        }
        return this.http.post<ICustomer>(
            `${this.environment_url}nfront/v1/proxy/users`,
            {
                user_type: UserType.AGENT,
                preferred_currencies: currencies,
                id: agentId,
                ...payload,
            },
            {
                headers: new HttpHeaders({
                    Apikey: localStorage.getItem('tenantKey') || '',
                }),
            },
        );
    }

    public createIdentification(payload: any): Observable<any> {
        return this.http.post<any>(
            `${this.environment_url}nfront/v1/proxy/users/35d170a4-ab5c-4016-a493-6c3e8c5c25e0/identifications`,
            { ...payload },
            {
                headers: new HttpHeaders({
                    Apikey: localStorage.getItem('tenantKey') || '',
                }),
            },
        );
    }

    public uploadIdentification(user_id: string, id: string, payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/users/${user_id}/identifications/${id}/files`, payload, {
            headers: new HttpHeaders({
                Apikey: localStorage.getItem('tenantKey') || '',
            }),
        });
    }

    public fetchCustomerAvatar(id: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}v1/users/${id}/avatar`, {
            headers: new HttpHeaders({
                Apikey: localStorage.getItem('tenantKey') || '',
                'x-no-authorization': 'true',
            }),
        });
    }

    public uploadCustomerAvatar(id: string): Observable<any> {
        return this.http.post<any>(
            `${this.environment_url}v1/users/${id}/avatar`,
            {},
            {
                headers: new HttpHeaders({
                    Apikey: localStorage.getItem('tenantKey') || '',
                    'x-no-authorization': 'true',
                }),
            },
        );
    }

    public searchCustomer(searchInput: string): Observable<IListResponse<ICustomer>> {
        return this.http.post<IListResponse<ICustomer>>(`${this.environment_url}nfront/v1/proxy/users:search`, {
            mobile: searchInput,
            after: null,
            limit: 10,
            order_by: 'desc',
            sort_by: 'created',
        });
    }

    public filterCustomers(payload: DeepPartial<ICustomerSearchRequest>): Observable<IListResponse<ICustomer>> {
        return this.http.post<IListResponse<ICustomer>>(`${this.environment_url}nfront/v1/proxy/users:search`, payload);
    }

    public filterCustomersMultiTenant(payload: string): Observable<IListResponse<ICustomer>> {
        return this.http.get<IListResponse<ICustomer>>(`${this.environment_url}internal/v1/admins/users/multitenant:search?${payload}`, {
            headers: this.headers,
        });
    }

    public fetchCustomerDetailsById(customerId: string): Observable<ICustomer> {
        return this.http.get<ICustomer>(`${this.environment_url}nfront/v1/proxy/users/${customerId}`);
    }

    public updateCustomerDetailsById(customerId: string, payload: DeepPartial<ICustomer>): Observable<ICustomer> {
        return this.http.put<ICustomer>(`${this.environment_url}nfront/v1/proxy/users/${customerId}`, payload);
    }

    public changeCustomerStatus(customerId: string, payload: any, changeStatusAdmin: boolean): Observable<ICustomer> {
        if (changeStatusAdmin) {
            return this.http.put<ICustomer>(`${this.environment_url}nfront/v1/proxy/admins/users/${customerId}:changeStatus`, payload);
        } else {
            return this.http.put<ICustomer>(`${this.environment_url}nfront/v1/proxy/users/${customerId}:changeStatus`, payload);
        }
    }

    public changeCustomerKYCStatus(customerId: string, payload: any): Observable<ICustomer> {
        return this.http.post<ICustomer>(`${this.environment_url}nfront/v1/proxy/users/${customerId}/kyc`, payload);
    }

    public fetchCustomerCards(customerId: string, limit = 10): Observable<IListResponse<ICard>> {
        return this.http.get<IListResponse<ICard>>(`${this.environment_url}nfront/v1/proxy/cards?user_id=${customerId}&limit=${limit}`);
    }

    public fetchCustomerCardsByTenant(tenantId: string, customerId: string, limit = 10): Observable<IListResponse<ICard>> {
        const headers = new HttpHeaders({
            'x-nymos-idempotency-key': '7cec1455-87c4-4cb4-a744-01aafd1dfa1e',
            'x-no-authorization': 'true',
        });
        return this.http.get<IListResponse<ICard>>(`${this.environment_url}internal/v1/admins/cards?userId=${customerId}&tenantId=${tenantId}&limit=${limit}&after=0`, {
            headers: headers,
        });
    }

    public fetchCustomerCardsForLazyLoading(customerId: string, payload: any): Observable<IListResponse<ICard>> {
        return this.http.get<IListResponse<ICard>>(`${this.environment_url}nfront/v1/proxy/cards?user_id=${customerId}&limit=${payload.limit}&after=${payload.after}`);
    }

    public fetchCustomerAccounts(customerId: string, limit = 10, after = ''): Observable<IListResponse<IAccount>> {
        return this.http.get<IListResponse<IAccount>>(`${this.environment_url}nfront/v1/proxy/accounts?user_id=${customerId}&limit=${limit}&after=${after}`);
    }

    public changeCardStatus(id: string, card: any): Observable<ICardStatusResponse> {
        return this.http.put<ICardStatusResponse>(`${this.environment_url}nfront/v1/proxy/cards/${id}/statuschange`, card);
    }

    public createAccount(accountDetails: DeepPartial<ICreateAccount>): Observable<IAccount> {
        return this.http.post<IAccount>(`${this.environment_url}nfront/v1/proxy/accounts`, accountDetails);
    }

    public fetchCustomerIdentificationFiles(userId: string): Observable<Array<ICustomerIdentificationFlattenedFile>> {
        return this.http.get<Array<ICustomerIdentification>>(`${this.environment_url}nfront/v1/proxy/users/${userId}/identifications`).pipe(
            map((identifications: Array<ICustomerIdentification>) =>
                identifications.flatMap((identification: ICustomerIdentification): Array<ICustomerIdentificationFlattenedFile> => {
                    return identification.files.map(
                        (file: ICustomerIdentificationFile): ICustomerIdentificationFlattenedFile => ({
                            ...file,
                            identification: identification,
                        }),
                    );
                }),
            ),
        );
    }

    public createCustomerIdentification(userId: string, payload: IIdentificationDocument): Observable<IIdentificationDocument> {
        return this.http.post<IIdentificationDocument>(`${this.environment_url}nfront/v1/proxy/users/${userId}/identifications`, payload);
    }

    public updateCustomerIdentification(userId: string, identificationId: string, payload: FormData): Observable<IIdentificationDocument> {
        return this.http.put<IIdentificationDocument>(`${this.environment_url}nfront/v1/proxy/users/${userId}/identifications/${identificationId}/files`, payload);
    }

    public uploadCustomerIdentificationDocuments(userId: string, identificationId: string, payload: FormData) {
        return this.http.post(`${this.environment_url}nfront/v1/proxy/users/${userId}/identifications/${identificationId}/files`, payload);
    }

    public fetchCustomerIdentificationDocumentFile(userId: string, identificationId: string, fileId: string): Observable<Blob> {
        return this.http.get<Blob>(`${this.environment_url}nfront/v1/proxy/users/${userId}/identifications/${identificationId}/files/${fileId}:browse`, {
            responseType: 'blob' as 'json',
        });
    }

    public fetchCustomerAnalytics(): Observable<ICustomerAnalyticsApiResponse> {
        const key = this.utils.getTenantKeyByName();
        return this.http.get<ICustomerAnalyticsApiResponse>(`${this.environment_url}nfront/v1/proxy/admins/user:analytics?duration=week&tenant=${key}`);
    }

    public fetchCustomerCount(): Observable<ICustomerCountApiResponse> {
        const key = this.utils.getTenantKeyByName();
        return this.http.get<ICustomerCountApiResponse>(`${this.environment_url}nfront/v1/proxy/admins/user:count?duration=week&tenant=${key}`);
    }

    public fetchCustomerAnalyticsMonthly(): Observable<ICustomerAnalyticsApiResponse> {
        const key = this.utils.getTenantKeyByName();
        return this.http.get<ICustomerAnalyticsApiResponse>(`${this.environment_url}nfront/v1/proxy/admins/user:analytics?duration=month&tenant=${key}`);
    }

    public fetchCustomerCountMonthly(): Observable<ICustomerCountApiResponse> {
        const key = this.utils.getTenantKeyByName();
        return this.http.get<ICustomerCountApiResponse>(`${this.environment_url}nfront/v1/proxy/admins/user:count?duration=month&tenant=${key}`);
    }

    public fetchCustomerIdvReports(userId: string) {
        return this.http.get<IdvReports>(`${this.environment_url}nfront/v1/proxy/identityverifications/reports/users/${userId}`);
    }

    public generateSDKToken(userId: string) {
        return this.http.post<sdkToken>(`${this.environment_url}nfront/v1/proxy/identityverifications/sdkToken`, {
            user_id: userId,
        });
    }
}
