import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable, of } from 'rxjs';
import { DeepPartial, IAccountStatement, ICard, ICardStatusChange, ILimit, IStatementPayload, LimitType } from '@lib/interfaces';
import { ICardLimitInfo } from "@lib/components/card-details/card-details.component";

@Injectable({
    providedIn: 'root',
})
export class CardsService {
    environment_url: string;
    constructor(private http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    fetchCardsLazyLoaded(params: URLSearchParams) {
        return this.http.get(`${this.environment_url}nfront/v1/proxy/cards?${params}`);
    }

    public changeCardStatus(id: string, payload: DeepPartial<ICardStatusChange>, isRestrictedStatus = false): Observable<ICard> {
        return this.http.put<ICard>(`${this.environment_url}nfront/v1/proxy/cards/${id}${isRestrictedStatus ? ':changeStatusAdmin' : ':statuschange'}`, payload);
    }

    public fetchCardLimits(cardId: string): Observable<ICardLimitInfo> {
        return this.http.get<ICardLimitInfo>(`${this.environment_url}nfront/v2/proxy/cards/${cardId}:limitinfo`);
    }

    public updateCardimitsDetailsById(cardId: string, payload: any): Observable<ILimit> {
        return this.http.put<ILimit>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}:customlimit`, payload);
    }

    public fetchCardsInfoByCardId(cardId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}`);
    }

    public linkLimitToCardProduct(cardProductId: string, limitIds: Array<string>, limitType: LimitType): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/cards/${cardProductId}/${limitType}:link`, { ids: limitIds }).pipe(
            map((linkedLimits: Array<string>) => {
                return linkedLimits;
            }),
        );
    }

    public unlinkLimitFromCardProduct(cardProductId: string, limitIds: Array<string>, limitType: LimitType): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/cards/${cardProductId}/${limitType}:unlink`, { ids: limitIds }).pipe(
            map((remainingLimits: Array<string>) => {
                return remainingLimits;
            }),
        );
    }

    public generateAccountStatement(accountStatementPayload: IStatementPayload): Observable<IAccountStatement> {
        return this.http.post<IAccountStatement>(`${this.environment_url}nfront/v1/proxy/credit/get-cardholder-statement`, accountStatementPayload);
    }

    public getAccountLinkedWithCard(cardId: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}/accounts`);
    }
}
