import { Permission } from '../enums';
import { MenuItem } from '../interfaces';

export const menuItems: MenuItem[] = [
    {
        title: 'Home',
        icon: 'home',
        link: '/home',
        permissions: [Permission.ProductManagement, Permission.CardProducts],
    },
    {
        title: 'Analytics',
        icon: 'report',
        link: '/report',
        permissions: [Permission.AccessManagement],
    },
    {
        title: 'Customers',
        icon: 'customer',
        link: '/customers',
        permissions: [Permission.Customer],
    },
    {
        title: 'Card Product',
        icon: 'product',
        link: '/card-product',
        permissions: [Permission.ProductManagement, Permission.CardProducts],
    },
    {
        title: 'Access Management',
        icon: 'access-management',
        link: '/access-management',
        permissions: [Permission.AccessManagement],
    },
    {
        title: 'Atm',
        icon: 'atm-icon',
        link: '/atm',
        permissions: [Permission.Locations],
    },
    {
        title: 'Customer Cash-In/Out',
        icon: 'customer-operations',
        link: '/customer-operations',
        permissions: [Permission.CardLessVouchers],
    },
    {
        title: 'Transactions',
        icon: 'transaction',
        link: '/transactions',
        permissions: [Permission.Transactions],
    },
    {
        title: 'Cards',
        icon: 'card-icon-sidemenu',
        link: '/cards',
        permissions: [Permission.Cards],
    },
    {
        title: 'Product Settings',
        icon: 'settings',
        link: '/product-settings',
        permissions: [Permission.ProductSettings],
    },
    {
        title: 'Bulk Upload Cash',
        icon: 'upload',
        link: '/bulk-upload-cash',
        permissions: [Permission.BulkUpload],
    },
    {
        title: 'Call Center',
        icon: 'customer',
        link: '/call-center',
        permissions: [Permission.Customer],
    },
];

export const lowerMenuItems: MenuItem[] = [
    {
        section: 'PERSONAL',
        title: 'Feedback',
        icon: 'feedback',
        permissions: [],
        link: '/feedback',
    },
    {
        title: 'Settings',
        icon: 'settings',
        permissions: [Permission.Settings, Permission.Logo],
        link: '/control-center/settings',
    },
    {
        section: 'MANUALS',
        title: 'API Catalog',
        icon: 'api-catalog',
        permissions: [],
        external: true,
        link: 'https://nymcard.com/docs/api',
    },
    {
        title: 'Getting Started Guide',
        icon: 'guide',
        permissions: [],
        external: true,
        link: 'https://docs.nymcard.com/',
    },
];
