import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { IFormArraySchema, IFormGroupSchema, Nullable, Optional } from '@lib/interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ParseUnderscoreStringPipe } from '@lib/pipes';
import { TableFilterDirective } from '@lib/modules/table/directives';
import { ITableTemplate } from '@lib/modules/table/interfaces';

type TFilterType = 'checkbox' | 'radio';
type TFilter = { value: string; template: TemplateRef<void>; checked: boolean };
type TFilterForm = { filters: Array<TFilter> };

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements ITableTemplate, AfterContentInit {
    @Input({ required: true }) public filterName = '';
    @Input({ required: true }) public filterType: TFilterType = 'checkbox';
    @Input() public showFilter = true;
    @Input() public filterSvgIcon: Optional<string>;
    @Input() public filterFontIcon: Optional<string>;

    @Output() public checkboxChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Output() public radioChange: EventEmitter<Nullable<string>> = new EventEmitter<Nullable<string>>();

    @ViewChild(TemplateRef<void>) public templateRef: Optional<TemplateRef<void>>;
    @ContentChildren(TableFilterDirective) protected filterOptions: Optional<QueryList<TableFilterDirective>>;

    protected appliedFilters: Array<string> = [];
    protected filterForm: FormGroup<IFormGroupSchema<TFilterForm>> = this.createFilterForm();

    protected get noFilterSet(): boolean {
        return this.filterForm.controls.filters.controls.every((formFilter: FormGroup<IFormGroupSchema<TFilter>>): boolean => !formFilter.controls.checked.value);
    }

    protected get filterText(): string {
        const filterText: string = this.filterName;

        if (this.appliedFilters.length === 0) return filterText;

        const appliedFilters: string = new ParseUnderscoreStringPipe().transform(this.appliedFilters.join(', '));

        return `${filterText} ${this.appliedFilters.length > 1 ? 'are' : 'is'} ${appliedFilters}`;
    }

    public constructor(private readonly formBuilder: FormBuilder) {}

    public ngAfterContentInit(): void {
        this.resetFilters();
    }

    protected setFilter(filterIndex: number): void {
        if (this.filterType === 'radio') this.markAllFiltersAsUnchecked();

        const selectedFilter: FormGroup<IFormGroupSchema<TFilter>> = this.filterForm.controls.filters.at(filterIndex);
        const newFilterValue: boolean = this.filterType === 'radio' ? true : !selectedFilter.controls.checked.value;

        selectedFilter.patchValue({ checked: newFilterValue });

        this.filterForm.markAsDirty();
    }

    protected applyFilter(): void {
        const filterFormFinalState: TFilterForm = this.filterForm.getRawValue();

        this.appliedFilters = [];

        for (const formFilter of filterFormFinalState.filters) {
            if (!formFilter.checked) continue;

            this.appliedFilters.push(formFilter.value);
        }

        if (this.filterType === 'checkbox') {
            this.checkboxChange.emit(this.appliedFilters);

            this.changeFilterFormInitialState(filterFormFinalState);

            return;
        }

        this.radioChange.emit(this.appliedFilters.length === 0 ? null : this.appliedFilters[0]);

        this.changeFilterFormInitialState(filterFormFinalState);
    }

    protected cancelFilter(): void {
        this.filterForm.reset();
    }

    protected removeAllFilters(): void {
        this.markAllFiltersAsUnchecked();

        this.filterForm.markAsDirty();
    }

    protected removeFilters(event: Event): void {
        event.stopPropagation();

        this.removeAllFilters();
        this.appliedFilters = [];

        this.checkboxChange.emit(this.appliedFilters);
        this.radioChange.emit(this.appliedFilters.length === 0 ? null : this.appliedFilters[0]);
    }

    protected resetFilters(): void {
        if (!this.filterOptions) return;

        this.filterOptions.forEach((filterOption: TableFilterDirective): void => {
            this.filterForm.controls.filters.push(
                this.formBuilder.group<IFormGroupSchema<TFilter>>({
                    value: this.formBuilder.nonNullable.control(filterOption.filterValue),
                    template: this.formBuilder.nonNullable.control(filterOption.templateRef),
                    checked: this.formBuilder.nonNullable.control(filterOption.checked),
                }),
            );
            if (filterOption.checked) {
                this.appliedFilters.push(filterOption.filterValue);
            }
        });
    }

    private markAllFiltersAsUnchecked(): void {
        this.filterForm.controls.filters.controls.forEach((filter: FormGroup<IFormGroupSchema<TFilter>>): void => {
            filter.controls.checked.setValue(false);
        });
    }

    private createFilterForm(): FormGroup<IFormGroupSchema<TFilterForm>> {
        return this.formBuilder.group<IFormGroupSchema<TFilterForm>>({
            filters: this.formBuilder.array<IFormArraySchema<TFilter>>([]),
        });
    }

    private changeFilterFormInitialState(newFilterFormState: TFilterForm): void {
        this.filterForm = this.formBuilder.group<IFormGroupSchema<TFilterForm>>({
            filters: this.formBuilder.array<IFormArraySchema<TFilter>>(
                newFilterFormState.filters.map((filterState: TFilter): IFormArraySchema<TFilter> => {
                    return this.formBuilder.group<IFormGroupSchema<TFilter>>({
                        value: this.formBuilder.nonNullable.control(filterState.value),
                        template: this.formBuilder.nonNullable.control(filterState.template),
                        checked: this.formBuilder.nonNullable.control(filterState.checked),
                    });
                }),
            ),
        });
    }
}
