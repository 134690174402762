import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appTableFilter]',
})
export class TableFilterDirective {
    @Input({ required: true }) public filterValue = '';
    @Input() public checked = false;

    public constructor(public readonly templateRef: TemplateRef<void>) {}
}
