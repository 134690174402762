import { Component, Input } from '@angular/core';
import { BaseValueFieldComponent } from '@lib/modules/value-field/base';
import { FormControl } from '@angular/forms';
import { Nullable, Optional } from '@lib/interfaces';

export type RangeControl = FormControl<Optional<Nullable<number>>>;

@Component({
    selector: 'app-value-field-range',
    templateUrl: './value-field-range.component.html',
    styleUrls: ['./value-field-range.component.scss'],
})
export class ValueFieldRangeComponent extends BaseValueFieldComponent {
    @Input({ required: true }) public minControl: RangeControl;
    @Input({ required: true }) public maxControl: RangeControl;
    @Input({ required: true }) public minControlDisplayName: string;
    @Input({ required: true }) public maxControlDisplayName: string;
    @Input() public minControlPlaceholder = '';
    @Input() public maxControlPlaceholder = '';

    protected get disabled(): boolean {
        return this.minControl.disabled && this.maxControl.disabled;
    }

    protected get maxValue(): number {
        return (this.maxControl.value ?? 0) * (3 / 2);
    }

    protected get minValue(): number {
        return this.minControl.value ?? 0;
    }

    protected setDragEndValue(control: RangeControl, value: number): void {
        if (!control.dirty) control.markAsDirty();
        if (!control.touched) control.markAllAsTouched();

        control.setValue(value);
        this.validateMinMax();
    }

    protected setInputValue(control: RangeControl, event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;

        const inputValue: number = parseFloat(inputElement.value);

        this.setDragEndValue(control, inputValue);
    }

    private validateMinMax(): void {
        if (!!this.minControl.value && !!this.maxControl.value && this.minControl.value > this.maxControl.value) {
            this.minControl.setErrors({ minGreaterThanMax: true });
            this.maxControl.setErrors({ minGreaterThanMax: true });
        } else {
            this.minControl.setErrors(null);
            this.maxControl.setErrors(null);
        }
    }
}
