<div
    [ngClass]="{
        small: size === 'SM',
        large: size === 'LG',
        suspended: card?.status === 'SUSPENDED' && this.currentTenant.split('_')[0] !== customerTenant.INC,
        'neo-suspended': card?.status === 'SUSPENDED' && this.currentTenant.split('_')[0] === customerTenant.INC
    }"
    [style.background-image]="'url(' + skinImg + ')'"
    [style]="tenantKey === 'tenant37_api' || tenantKey === 'tenant01_api' ? { padding: '40px 14px' } : {}"
    class="card-template"
>
    <div>
        <app-spinner [isLoading]="!card" />
        <div class="credentials d-flex flex-column text-white" [ngClass]="{ 'nasspay-credentials': isNassPay }">
            <div *ngIf="card?.status !== 'SUSPENDED'">
                <div
                    [ngClass]="{
                        'fs-5': size === 'MD',
                        'fs-2': size === 'LG'
                    }"
                    class="lh-1 number fw-medium"
                >
                    {{ card && !isCardProduct ? getCardNumber(card.bin, card.last_four) : card && isCardProduct ? getCardBin(card.bin, card?.last_four) : '' }}
                </div>
            </div>
            <div *ngIf="card?.status !== 'SUSPENDED'" class="d-flex flex-column-reverse">
                <div>
                    <div
                        [ngClass]="{
                            'fs-7': size === 'MD',
                            'fs-5': size === 'LG'
                        }"
                        class="fw-normal name-on-card card-name-width text-truncate text-uppercase"
                    >
                        {{ card?.name_on_card }}
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div [ngClass]="{ 'label-md': size === 'MD' }" class="label text-uppercase expLabel">valid thru</div>
                    <div [ngClass]="{ 'fs-7': size === 'MD' }" class="validity">
                        {{ !isCardProduct ? card?.expiry_date : card?.expiry_date + ' M' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
