import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { ApiCustomErrorMessage, ApiResponseError, Optional } from '@lib/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isArray } from 'chart.js/helpers';
import { NamedSubscription } from '@lib/enums';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
    private subscriptions: Array<Subscription> = [];
    private namedSubscriptions: Array<Optional<Subscription>> = [];

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => {
            subscription.unsubscribe();
        });
    }

    protected addNamedSubscription(namedSubscription: NamedSubscription, subscription: Subscription): void {
        this.namedSubscriptions[namedSubscription] = subscription;
    }

    protected removeNamedSubscription(namedSubscription: NamedSubscription): void {
        const subscription: Optional<Subscription> = this.namedSubscriptions[namedSubscription];

        if (!subscription) return;

        if (!subscription.closed) subscription.unsubscribe();

        delete this.namedSubscriptions[namedSubscription];
    }

    protected addSubscription(subscriptions: Array<Subscription>): void;
    protected addSubscription(...subscriptions: Array<Subscription>): void;
    protected addSubscription(subscriptions: Subscription | Array<Subscription>, ...moreSubscriptions: Array<Subscription>): void {
        subscriptions = isArray(subscriptions) ? subscriptions : [subscriptions, ...moreSubscriptions];

        this.subscriptions.push(...subscriptions);
    }

    protected createApiErrorSnackBar(snackBar: MatSnackBar, error: ApiResponseError, errorMessages?: ApiCustomErrorMessage[]) {
        let message = '';
        if (error.error?.detail)  {
            message = error.error?.detail ;
        }else if (error?.detail)  {
            message = error?.detail ;
        } else if (errorMessages) {
            const customError = errorMessages.find((errorMessage) => errorMessage.status === error.status);
            message = customError?.message ?? this.getDefaultErrorMessage(error.status);
        } else {
            message = this.getDefaultErrorMessage(error.status);
        }

        snackBar.open(message, 'Close', {
            duration: 7000,
        });
    }

    protected createApiSuccessSnackBar(snackBar: MatSnackBar, message: string): void {
        snackBar.open(message, 'Close', {
            duration: 7000,
        });
    }

    private getDefaultErrorMessage(statusCode: number): string {
        switch (statusCode) {
            case 400:
                return 'Bad Request. Please check your request parameters.';
            case 401:
                return 'Unauthorized. Please log in.';
            case 403:
                return 'Forbidden. You do not have permission to perform this action.';
            case 404:
                return 'Not Found. The requested resource could not be found.';
            case 500:
                return 'Internal Server Error. Please try again later.';
            default:
                return 'Something went wrong. Please try again!';
        }
    }
}
