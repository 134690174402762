import { Injectable } from '@angular/core';
import { TransactionTypes } from '@lib/constants/transaction.constant';
import { tenantSetting } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class Utils {
    getTransactionType(txnType: string): string {
        if (txnType === '') return 'N/A';
        return TransactionTypes[txnType as keyof typeof TransactionTypes];
    }

    getPermissions(permissionName: string): boolean {
        const allPermissions = localStorage.getItem('permissions')?.split(',');
        if (allPermissions && allPermissions.some((permission) => permission === permissionName)) {
            return true;
        } else {
            return false;
        }
    }

    getTenantKeyByName(): string {
        const tenantKey = localStorage.getItem('tenantKey') || '';
        const { key = '' } = tenantSetting.find((item) => tenantKey.startsWith(item.name)) || {};
        return key;
    }

    getCurrencyByTenantName(currency: any[], tenantName?: string) {
        if (!tenantName) {
            return currency;
        }

        return currency.filter(curr => !curr.renderTenants || curr.renderTenants.includes(tenantName));
    }

    downloadFile(data: Blob) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    }
}
