import { Component, OnInit } from '@angular/core';
import { AppAssets } from './app.assests';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TenantConfigurationService } from '@lib/services';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    tenantKey = localStorage.getItem('tenantKey') || '';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    constructor(appAssets: AppAssets, private tenantConfigurationService: TenantConfigurationService) {}

    ngOnInit(): void {
        this.tenantConfigurationService.setFavicon(this.tenantKey);
    }
}
