export enum AccountType {
    PREPAID = 'PREPAID',
    COLLATERAL = 'COLLATERAL',
    CREDIT = 'CREDIT',
    SECURITY = 'SECURITY',
    MASTER = 'MASTER',
}

export enum AccountStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    TERMINATED = 'TERMINATED',
}

export enum FundType {
    INITIAL_LOAD_FUNDS = 'initialLoadFunds',
    LOAD_FUNDS = 'loadFunds',
    UNLOAD_FUNDS = 'unloadFunds',
    TRANSFERS = 'transfers',
}

export enum AllowedLoadType {
    LOAD = 'LOAD',
    INITIAL_LOAD = 'INITIAL_LOAD',
}

export interface IAccount {
    id: string;
    user_id: string;
    card_product_id: string;
    type: AccountType;
    balances: IBalance;
    ledger_balances: IBalance;
    status: string;
    created: string;
    modified: string;
    default_account: boolean;
    currencies: string[];
    wallet_details: IWallet[];
}

export interface IWallet {
    currency: string;
    priority: number;
    status: string;
    allowed_load_type: AllowedLoadType;
}

export interface IBalance {
    [key: string]: number;
}

export interface ILinkAccountCard {
    accountId: string;
    ids: string[];
    dry_run: boolean;
}

export interface IAccountFunds {
    amount: number;
    currency: string;
    note: string;
    dry_run: boolean;
}
export interface ITransferAmount {
    amount: number;
    fromCurrency: string;
}

export interface IAccountFundsResponse {
    transaction_id: string;
    account_id: string;
    request: {
        currency: string;
        amount: number;
        note: string;
        dry_run: boolean;
    };
    result: {
        fee_amount: number;
        status: string;
        status_reason: string;
        status_description: string;
    };
    created: string;
    modified: string;
}

export type ICreateAccount = Pick<IAccount, 'user_id' | 'card_product_id' | 'type' | 'currencies'>;
