export enum TransactionTypes {
    PURCHASE = 'Purchase',
    CASH_WITHDRAWAL = 'Cash Withdrawal',
    REFUND = 'Refund',
    BALANCE_INQUIRY = 'Balance Inquiry',
    ACCOUNT_VERIFICATION = 'Account Verification',
    WALLET_DEBIT = 'Wallet Debit',
    WALLET_CREDIT = 'Wallet Credit',
    WALLET_TRANSFER = 'Wallet Transfer',
    WALLET_DEBIT_REVERSAL= 'Wallet Debit Reversal',
    INITIAL_LOAD= 'Wallet Initial Credit'
}
