import { Injectable, Type } from '@angular/core';
import { customerTenant } from '@env/environment';
import { tenantImagesMap } from '@lib/constants/tenant.constant';
import { TenantFlow } from '@lib/enums';
import { TenantFlowConfig } from '@lib/interfaces/tenant-config.interface';

@Injectable({
    providedIn: 'root',
})
export class TenantConfigurationService {
    private tenantFlows: Record<string, TenantFlowConfig> = {
        [customerTenant.FIB]: {
            [TenantFlow.AGENT_CREATION]: async () => (await import('@pages/customer/pages')).CreateAgentComponent,
            [TenantFlow.FUND_TRANSFER_ALLOWED]: false,
        },
        [customerTenant.INC]: {
            [TenantFlow.AGENT_CREATION]: async () => (await import('@pages/customer/pages')).CreateAgentIncComponent,
            [TenantFlow.FUND_TRANSFER_ALLOWED]: true,
        },
        [customerTenant.NASS]: {
            [TenantFlow.AGENT_CREATION]: async () => (await import('@pages/customer/pages')).CreateAgentNassComponent,
            [TenantFlow.FUND_TRANSFER_ALLOWED]: false,
        },
    };

    constructor() {}

    /**
     * Checks if the given tenant has a specific flow.
     * @param tenant - The tenant string to check.
     * @param flowType - The type of flow (e.g., 'agentCreation', 'fundTransfer', 'agentDocumentationUrl').
     * @returns boolean - True if the tenant has the specified flow, false otherwise.
     */
    isTenantAllowed(tenant: string, flowType: TenantFlow): boolean {
        return Object.keys(this.tenantFlows).some((allowedTenant) => tenant.startsWith(allowedTenant) && !!this.tenantFlows[allowedTenant][flowType]);
    }

    /**
     * Retrieves a specific flow for a tenant.
     * @param tenant - The tenant string.
     * @param flowType - The type of flow (e.g., 'agentCreation', 'fundTransfer', 'agentDocumentationUrl').
     * @returns T | undefined - The requested flow if available, otherwise undefined.
     */
    private getFlow<T>(tenant: string, flowType: keyof TenantFlowConfig): T | undefined {
        const allowedTenant = Object.keys(this.tenantFlows).find((key) => tenant.startsWith(key));
        return allowedTenant ? (this.tenantFlows[allowedTenant][flowType] as T | undefined) : undefined;
    }

    /**
     * Retrieves the dynamic component loader function for a given flow type.
     * @param tenant - The tenant string.
     * @param flowType - The type of flow that loads a component (e.g., 'agentCreation', 'fundTransfer').
     * @returns (() => Promise<Type<unknown>>) | undefined - A function that loads the component dynamically.
     */
    getComponentForFlow(tenant: string, flowType: keyof TenantFlowConfig): (() => Promise<Type<unknown>>) | undefined {
        return this.getFlow<() => Promise<Type<unknown>>>(tenant, flowType);
    }

    /**
     * Retrieves and interpolates a URL flow (e.g., agent documentation URL) by replacing placeholders.
     * @param tenant - The tenant string.
     * @param flowType - The type of flow that contains a URL (e.g., 'agentDocumentationUrl').
     * @param params - Key-value pairs to replace placeholders in the URL.
     * @returns string | undefined - The formatted URL or undefined if not found.
     */
    getInterpolatedFlowUrl(tenant: string, flowType: TenantFlow, params: Record<string, string>): string | undefined {
        const urlTemplate = this.getFlow<string>(tenant, flowType);
        return urlTemplate ? Object.keys(params).reduce((url, key) => url.replace(`\${${key}}`, params[key]), urlTemplate) : undefined;
    }

    /**
     * Retrieves url of image specific to tenant if found otherwise return empty string.
     * @param tenant - The tenant string.
     * @param imgName - The image you want to lookup.
     * @returns <string> - A url of image or a empty string.
     */
    getImageSpecificToTenant(tenant: string, imgName: string): string {
        const tenantImages = tenantImagesMap[tenant.split('_')[0]];
        const defaultImages = tenantImagesMap['DEFAULT'];

        if (tenantImages && tenantImages[imgName]) {
            return tenantImages[imgName];
        } else if (defaultImages && defaultImages[imgName]) {
            return defaultImages[imgName];
        }
        return '';
    }

    /**
     * Sets the favicon based on the tenant's configuration.
     * @param tenantKey - The tenant identifier.
     */
    setFavicon(tenantKey: string): void {
        const url = this.getImageSpecificToTenant(tenantKey, 'favicon');
        if (!url) return;

        const head = document.head;
        let existingFavicon = document.querySelector("link[rel='icon']");

        if (!existingFavicon) {
            existingFavicon = document.createElement('link');
            existingFavicon.setAttribute('rel', 'icon');
            head.appendChild(existingFavicon);
        }

        existingFavicon.setAttribute('href', url);
    }
}
